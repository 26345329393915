<template>
  <van-action-sheet v-model="show" @close="closeAction">
    <div class="content">
      <van-picker
        title="请选择"
        :columns="columns"
        show-toolbar
        @confirm="onConfirm"
        @cancel="onCancel"
      />
    </div>
  </van-action-sheet>
</template>
<script>
export default {
  components: {},
  props: {
    isShow: Boolean,
    isShowStr: String,
    inValue: String,
    inValueId: String,
    columns: Array,
    index: Number,
  },
  watch: {
    isShow(newVlue) {
      this.show = newVlue;
    },
  },
  data() {
    return {
      show: false,
      value: {},
    };
  },
  onLoad() {},
  methods: {
    // 关闭弹窗
    closeAction() {
      if (this.isShowStr == "holderRelationship2") {
        this.$parent.holderRelationship2[this.index] = false;
      } else {
        this.$parent[this.isShowStr] = false;
      }
      //  this.$emit('callback', this.value.text, this.value.id, this.index,this.value.index)
      this.show = false;
    },
    // 提交
    onConfirm(value) {
      this.value = value;
      if (this.isShowStr == "holderRelationship2") {
        this.$parent.holderRelationship2[this.index] = false;
      } else {
        this.$parent[this.isShowStr] = false;
      }
      this.$emit(
        "callback",
        this.value.text,
        this.value.id,
        this.index,
        this.value.index,
        this.value.valueId
      );
      this.show = false;
    },
    // 取消
    onCancel() {
      if (this.isShowStr == "holderRelationship2") {
        this.$parent.holderRelationship2[this.index] = false;
      } else {
        this.$parent[this.isShowStr] = false;
      }
      this.$emit(
        "callback",
        this.value.text,
        this.value.id,
        this.index,
        this.value.index,
        this.value.valueId
      );
      this.show = false;
    },
  },
};
</script>


<style scoped lang="scss">
.content {
  padding: 16px 16px 16px;
}
</style>
