<template>
  <div class="insure">
    <div class="applicantContent">
      <!-- 投保人模块 -->
      <div class="applicant-title">
        <div class="titleNum">1</div>
        <div class="titleName">投保人信息</div>
      </div>
      <div class="form">
        <div class="uni-form-item">
          <div class="tit">姓名</div>
          <input
            class="uni-input"
            placeholder="点击输入姓名"
            @blur="rbCalc"
            maxlength="20"
            v-model.trim="fromData.applicant.name"
          />
        </div>
        <div class="uni-form-item">
          <div class="tit">证件类型</div>
          <div class="uni-input" @click="changeCardTypeApplicant">
            <div :class="cardTypeApplicantValue ? 'input' : 'inputno'">
              {{
                cardTypeApplicantValue ? cardTypeApplicantValue : "选择证件类型"
              }}
            </div>
          </div>
          <CommonPiker
            :isShow="holderCardTypeApplicant"
            isShowStr="holderCardTypeApplicant"
            @callback="callbackCardTypeApplicant"
            :columns="cardTypeList"
          />
          <img class="arrow" src="./static/arrow-icon.png" mode="widthFix" />
        </div>

        <div class="uni-form-item" v-if="fromData.applicant.certType == '1'">
          <div class="tit">国籍</div>
          <div class="uni-input" @click="changeNationalityApplicant">
            <div :class="nationalityApplicantValue ? 'input' : 'inputno'">
              {{
                nationalityApplicantValue
                  ? nationalityApplicantValue
                  : "选择国籍"
              }}
            </div>
          </div>
          <CommonPiker
            :isShow="holderNationalityApplicant"
            isShowStr="holderNationalityApplicant"
            @callback="callbackNationalityApplicant"
            :columns="nationalityList"
          />
          <img class="arrow" src="./static/arrow-icon.png" mode="widthFix" />
        </div>

        <div class="uni-form-item">
          <div class="tit">证件号码</div>
          <input
            class="uni-input"
            placeholder="点击输入证件号码"
            @blur="rbCalc"
            v-model.trim="fromData.applicant.certNo"
            @input="wathcApplicantCertNo"
            maxlength="18"
          />
        </div>
        <div class="uni-form-item">
          <div class="tit">证件有效起期</div>
          <div class="uni-input" @click="isStartTB = true">
            <div
              :class="fromData.applicant.certStartDate ? 'input' : 'inputno'"
            >
              {{
                fromData.applicant.certStartDate
                  ? fromData.applicant.certStartDate
                  : "选择证件有效起期"
              }}
            </div>
          </div>
          <DatePiker
            :isShow="isStartTB"
            :typeDate="'tbStart'"
            @cancelDate="cancelDate"
            @callbackDate="callbackCertStartDate"
          />
          <img class="arrow" src="./static/arrow-icon.png" mode="widthFix" />
        </div>
        <div class="uni-form-item">
          <div class="tit">是否长期</div>
          <van-radio-group
            v-model="tbRadio"
            direction="horizontal"
            @change="changeTbRadio"
          >
            <van-radio name="1">是</van-radio>
            <van-radio name="2">否</van-radio>
          </van-radio-group>
        </div>
        <div class="uni-form-item" v-if="tbRadio == '2'">
          <div class="tit">证件有效止期</div>
          <div class="uni-input" @click="isEndTB = true">
            <div :class="fromData.applicant.certEndDate ? 'input' : 'inputno'">
              {{
                fromData.applicant.certEndDate
                  ? fromData.applicant.certEndDate
                  : "选择证件有效止期"
              }}
            </div>
          </div>
          <DatePiker
            :isShow="isEndTB"
            :typeDate="'tbEnd'"
            @cancelDate="cancelDate"
            @callbackDate="callbackCertStartDate"
          />
          <img class="arrow" src="./static/arrow-icon.png" mode="widthFix" />
        </div>

        <div class="uni-form-item">
          <div class="tit">出生日期</div>
          <div class="uni-input" @click="isApplicantBirthaday = true">
            <div :class="fromData.applicant.birthday ? 'input' : 'inputno'">
              {{
                fromData.applicant.birthday
                  ? fromData.applicant.birthday
                  : "选择出生日期"
              }}
            </div>
          </div>
          <DatePiker
            :isShow="isApplicantBirthaday"
            :typeDate="'applicantBirthaday'"
            @cancelDate="cancelDate"
            @callbackDate="callbackCertStartDate"
          />
          <img class="arrow" src="./static/arrow-icon.png" mode="widthFix" />
        </div>

        <div class="uni-form-item">
          <div class="tit">性别</div>
          <div class="uni-input" @click="changeSexApplicant">
            <div :class="sexApplicantValue ? 'input' : 'inputno'">
              {{ sexApplicantValue ? sexApplicantValue : "选择性别" }}
            </div>
          </div>
          <CommonPiker
            :isShow="holderSexApplicant"
            isShowStr="holderSexApplicant"
            @callback="callbackSexApplicant"
            :columns="sexList"
          />
          <img class="arrow" src="./static/arrow-icon.png" mode="widthFix" />
        </div>

        <div class="uni-form-item">
          <van-field
            v-model.trim="fromData.applicant.phone"
            type="tel"
            label="手机号码"
            @blur="rbCalc"
            placeholder="点击输入手机号码"
            maxlength="11"
          />
        </div>
        <div class="uni-form-item">
          <van-field
            @blur="rbCalc"
            v-model.trim="fromData.applicant.address"
            label="联系地址"
            placeholder="点击输入联系地址"
            maxlength="50"
          />
        </div>
        <div class="uni-form-item">
          <van-field
            @blur="rbCalc"
            v-model.trim="fromData.applicant.email"
            label="电子邮箱"
            placeholder="点击输入电子邮箱"
            maxlength="50"
          />
        </div>
      </div>
      <!-- 被保人模块 -->
      <div class="applicant-title">
        <div class="titleNum">2</div>
        <div class="titleName">被保人信息</div>
      </div>
      <div class="form">
        <div class="uni-form-item">
          <div class="tit">与投保人关系</div>
          <div class="uni-input" @click="holderRelationship = true">
            <div :class="relationshipValue ? 'input' : 'inputno'">
              {{ relationshipValue ? relationshipValue : "与投保人关系" }}
            </div>
          </div>
          <CommonPiker
            :isShow="holderRelationship"
            isShowStr="holderRelationship"
            @callback="callbackRelationship"
            :columns="relationshipList"
          />
          <img class="arrow" src="./static/arrow-icon.png" mode="widthFix" />
        </div>
        <div class="uni-form-item">
          <div class="tit">姓名</div>
          <input
            class="uni-input"
            placeholder="点击输入姓名"
            @blur="rbCalc"
            maxlength="20"
            v-model.trim="insuredFrom.name"
          />
        </div>
        <div class="uni-form-item">
          <div class="tit">证件类型</div>
          <div class="uni-input" @click="changeCardTypeInsured">
            <div :class="cardTypeInsuredValue ? 'input' : 'inputno'">
              {{ cardTypeInsuredValue ? cardTypeInsuredValue : "选择证件类型" }}
            </div>
          </div>
          <CommonPiker
            :isShow="holderCardTypeInsured"
            isShowStr="holderCardTypeInsured"
            @callback="callbackCardTypeInsured"
            :columns="cardTypeList"
          />
          <img class="arrow" src="./static/arrow-icon.png" mode="widthFix" />
        </div>
        <div class="uni-form-item" v-if="insuredFrom.certType == '1'">
          <div class="tit">国籍</div>
          <div class="uni-input" @click="changeNationalityInsured">
            <div :class="nationalityInsuredValue ? 'input' : 'inputno'">
              {{
                nationalityInsuredValue ? nationalityInsuredValue : "选择国籍"
              }}
            </div>
          </div>
          <CommonPiker
            :isShow="holderNationalityInsured"
            isShowStr="holderNationalityInsured"
            @callback="callbackNationalityInsured"
            :columns="nationalityList"
          />
          <img class="arrow" src="./static/arrow-icon.png" mode="widthFix" />
        </div>

        <div class="uni-form-item">
          <div class="tit">证件号码</div>
          <input
            class="uni-input"
            @blur="rbCalc"
            maxlength="18"
            placeholder="点击输入证件号码"
            v-model.trim="insuredFrom.certNo"
            @input="wathcInsuredCertNo"
          />
        </div>
        <div class="uni-form-item">
          <div class="tit">证件有效起期</div>
          <div class="uni-input" @click="isStartBB = true">
            <div :class="insuredFrom.certStartDate ? 'input' : 'inputno'">
              {{
                insuredFrom.certStartDate
                  ? insuredFrom.certStartDate
                  : "选择证件有效起期"
              }}
            </div>
          </div>
          <DatePiker
            :isShow="isStartBB"
            :typeDate="'bbStart'"
            @cancelDate="cancelDate"
            @callbackDate="callbackCertStartDate"
          />
          <img class="arrow" src="./static/arrow-icon.png" mode="widthFix" />
        </div>
        <div class="uni-form-item">
          <div class="tit">是否长期</div>
          <van-radio-group
            v-model="bbRadio"
            direction="horizontal"
            @change="changeBbRadio"
          >
            <van-radio name="1">是</van-radio>
            <van-radio name="2">否</van-radio>
          </van-radio-group>
        </div>
        <div class="uni-form-item" v-if="bbRadio == '2'">
          <div class="tit">证件有效止期</div>
          <div class="uni-input" @click="isEndBB = true">
            <div :class="insuredFrom.certEndDate ? 'input' : 'inputno'">
              {{
                insuredFrom.certEndDate
                  ? insuredFrom.certEndDate
                  : "选择证件有效止期"
              }}
            </div>
          </div>
          <DatePiker
            :isShow="isEndBB"
            :typeDate="'bbEnd'"
            @cancelDate="cancelDate"
            @callbackDate="callbackCertStartDate"
          />
          <img class="arrow" src="./static/arrow-icon.png" mode="widthFix" />
        </div>
        <div class="uni-form-item">
          <div class="tit">出生日期</div>
          <div class="uni-input" @click="isInsuredBirthaday = true">
            <div :class="insuredFrom.birthday ? 'input' : 'inputno'">
              {{ insuredFrom.birthday ? insuredFrom.birthday : "选择出生日期" }}
            </div>
          </div>
          <DatePiker
            :isShow="isInsuredBirthaday"
            :typeDate="'insuredBirthaday'"
            @cancelDate="cancelDate"
            @callbackDate="callbackCertStartDate"
          />
          <img class="arrow" src="./static/arrow-icon.png" mode="widthFix" />
        </div>
        <div class="uni-form-item">
          <div class="tit">性别</div>
          <div class="uni-input" @click="changeSexInsured">
            <div :class="sexInsuredValue ? 'input' : 'inputno'">
              {{ sexInsuredValue ? sexInsuredValue : "选择性别" }}
            </div>
          </div>
          <CommonPiker
            :isShow="holderSexInsured"
            isShowStr="holderSexInsured"
            @callback="callbackSexInsured"
            :columns="sexList"
          />
          <img class="arrow" src="./static/arrow-icon.png" mode="widthFix" />
        </div>

        <div class="uni-form-item">
          <div class="tit">手机号码</div>
          <input
            class="uni-input"
            @blur="rbCalc"
            type="tel"
            maxlength="11"
            placeholder="点击输入手机号码"
            v-model.trim="insuredFrom.phone"
          />
        </div>
        <div class="uni-form-item">
          <div class="tit">联系地址</div>
          <input
            class="uni-input"
            @blur="rbCalc"
            placeholder="点击输入联系地址"
            v-model.trim="insuredFrom.address"
            maxlength="50"
          />
        </div>
        <div class="uni-form-item">
          <div class="tit">电子邮箱</div>
          <input
            class="uni-input"
            @blur="rbCalc"
            placeholder="点击输入电子邮箱"
            v-model.trim="insuredFrom.email"
            maxlength="50"
          />
        </div>
      </div>
      <!-- 保障计划 -->
      <div class="applicant-title">
        <div class="titleNum">3</div>
        <div class="titleName">保障计划</div>
      </div>
      <div class="btns">
        <div
          class="item"
          v-for="(item, index) in productDetail.plans"
          :key="index"
        >
          <div
            class="name"
            @click="changeTit(index, item)"
            :class="planActive === index ? 'active' : 'no-active'"
          >
            {{ item.securityPlanName }}
          </div>
          <div class="equity" @click="rightsAndInterests(item)">
            <img src="../../assets/equity.png" alt="" />
            <p>权益详情</p>
          </div>
        </div>
      </div>
      <div class="responsibility">
        <div class="title">
          <div
            :class="
              responsibilityActive == index
                ? 'responsibility-bg-active item'
                : 'responsibility-bg item'
            "
            v-for="(item, index) in responsibilityData"
            :key="index"
            @click="changeResponsibility(index)"
          >
            <p
              :class="
                responsibilityActive == index
                  ? 'responsibilityColor-active'
                  : 'responsibilityColor'
              "
            >
              {{ item }}
            </p>
          </div>
        </div>
        <div class="msg" :class="{ bgStyle: responsibilityActive !== 0 }">
          <p>保障范围</p>
          <div>
            <img src="../../assets/range-icon.png" alt="" />
            <p>
              {{
                responsibilityActive == 0
                  ? "合同约定范围内，投保成功后1年内，被保人接种的疫苗，针次不限"
                  : "合同约定范围内，投保成功后1年内，被保人接种的第1针疫苗"
              }}
            </p>
          </div>
        </div>
      </div>
      <div class="insure-date">
        <div class="item">
          <p>生效日期</p>
          <p>{{ todayDate }}</p>
        </div>
        <div class="item">
          <p>终止日期</p>
          <p>{{ futureDate }}</p>
        </div>
        <div class="item">
          <p>保障期间</p>
          <p>1年</p>
        </div>
      </div>
    </div>
    <!-- 提交模块 -->
    <!-- <SubmitTip
      ref="submitTip"
      @submit="apply"
      :premium="productDetail.premium"
    /> -->
    <div class="footer">
      <div class="file">
        <div @click="checkFile = !checkFile">
          <img
            :src="
              require(checkFile
                ? '../../assets/file-check-icon.png'
                : '../../assets/file-icon.png')
            "
            alt=""
          />
        </div>

        <p>我已阅读</p>
        <div class="list">
          <p @click="insuranceNotice">投保须知</p>
          <p @click="fileList('productTerms')">保险条款</p>
          <p @click="fileList('rateTable')">费率表</p>
          <p @click="customerNotification">客户告知</p>
        </div>
      </div>
      <div class="handle">
        <div class="price">
          价格：¥
          <div class="num">{{ totalPrem ? totalPrem : "--" }}</div>
        </div>
        <div class="btn" @click="rbInsure">立即投保</div>
      </div>
    </div>
  </div>
</template>
<script>
import CommonPiker from "./components/commonPiker.vue";
import DatePiker from "./components/datePiker.vue";
import {
  relationshipList,
  cardTypeList,
  sexList,
  nationalityList,
} from "../../assets/js/common";
import {
  productDetail,
  productBenefitDetail,
  rbCalc,
  rbInsure,
  selectRisk,
} from "../../utils/api";
import { validIdCard, validPhone, validEmail } from "../../utils/validate";
// import SubmitTip from "../../components/submitTip.vue";
import moment from "moment";
export default {
  components: {
    CommonPiker,
    DatePiker,
  },
  data() {
    return {
      fromData: {
        riskCodeM: "", //主险编码
        category: "",
        mainInsuranceCompanyId: "",
        mainInsuranceCompanyName: "",
        productId: "",
        productName: "",
        subInsuranceCompanyId: "",
        subInsuranceCompanyName: "",
        planId: "",
        planName: "",
        risks: [],
        applicant: {
          //【投保人信息】
          name: "", //姓名
          certNo: "", //证件号码
          phone: "", //联系电话
          certType: "",
          nationality: "", //国籍
          email: "", //电子邮箱
          sex: "",
          birthday: "",
          age: "",
          certStartDate: "", //起始日期
          certEndDate: "3000-01-01", //终止日期
          address: "", //联系地址
        },
        insureds: [],
        inputFactors: {
          DutyCode: "",
          choiceType: 1,
          inoculationPoint: sessionStorage.getItem("cityName"),
        },
      },
      insuredFrom: {
        //【被保人信息】
        relation: "", //与投保人关系
        name: "", //姓名
        certNo: "", //证件号码
        certType: "",
        nationality: "", //国籍
        sex: "",
        birthday: "",
        age: "",
        phone: "", //联系电话
        email: "",
        certStartDate: "", //起始日期
        certEndDate: "3000-01-01", //终止日期
        address: "", //联系地址
      },

      holderRelationship: false,
      productDetail: {}, //产品详情
      planActive: 0, //选中计划index
      relationshipList: [], //与投保人关系的data
      relationshipValue: "", //关系值
      responsibilityData: ["针次不限", "保一针"],
      responsibilityActive: 0,

      cardTypeList: [], //证件类型的data
      cardTypeApplicantValue: "", //投保人证件类型值
      holderCardTypeApplicant: false, //投保人证件类型弹框
      applicantBirthaday: "", //投保人生日
      isApplicantBirthaday: false, //投保人生日弹框展示
      sexList: [], //性别类型的data
      sexApplicantValue: "", //投保人性别值
      holderSexApplicant: false, //投保人性别弹框
      nationalityList: [], //国籍类型data
      nationalityApplicantValue: "", //投保人证件类型值
      holderNationalityApplicant: false, //投保人证件类型弹框

      cardTypeInsuredValue: "", //被保人证件类型值
      holderCardTypeInsured: false, //被保人证件类型弹框
      insuredBirthaday: "", //被保人生日
      isInsuredBirthaday: false, //被保人生日弹框展示
      sexInsuredValue: "", //被保人性别值
      holderSexInsured: false, //被保人性别弹框
      nationalityInsuredValue: "", //投保人证件类型值
      holderNationalityInsured: false, //投保人证件类型弹框

      checkFile: false, //是否已经阅读相关文件
      riskData: [], //险种数据
      totalPrem: "", //总保费
      riskAmnt: "", //试算保额
      isFirstEnter: false,
      todayDate: "", //当前日期
      futureDate: "", //当前日期一年后的日期
      isStartTB: false, //是否展示投保人起始日期弹窗
      isEndTB: false, //是否展示投保人截止日期弹窗
      isStartBB: false, //是否展示被保人起始日期弹窗
      isEndBB: false, //是否展示被保人截止日期弹窗
      tbRadio: "1", //投保人是否长期
      bbRadio: "1", //被保人是否长期
    };
  },
  beforeRouteEnter(to, from, next) {
    if (
      from.name == "confirm" ||
      from.name == "confirm" ||
      from.name == "fileList" ||
      from.name == "pdf" ||
      from.name == "insuranceNotice" ||
      from.name == "rightsAndInterests"
    ) {
      to.meta.isBack = true;
    }
    next();
  },
  activated() {
    if (!this.$route.meta.isBack || this.isFirstEnter) {
    }
    this.$route.meta.isBack = false;
    this.isFirstEnter = false;
  },
  mounted() {
    this.relationshipList = relationshipList;
    this.cardTypeList = cardTypeList;
    this.sexList = sexList;
    this.nationalityList = nationalityList;
    //获取当前日期
    this.todayDate = moment().format("YYYY年MM月DD日");
    let date = moment().format("YYYY-MM-DD");
    //获取一年后的日期
    var d2 = new Date(date);
    d2.setFullYear(d2.getFullYear() + 1);
    d2.setDate(d2.getDate() - 1);
    this.futureDate = moment(d2).format("YYYY年MM月DD日");

    const productId = sessionStorage.getItem("productId");
    this.getProductDetail(productId);
    this.selectRisk(productId);
  },
  methods: {
    //选择投保人证件号是否长期
    changeTbRadio() {
      this.fromData.applicant.certEndDate = "";
      if (this.tbRadio == "1") {
        this.fromData.applicant.certEndDate = "3000-01-01";
      }
    },
    //选择被保人证件号是否长期
    changeBbRadio() {
      //选择关系为本人时，
      if (this.insuredFrom.relation == "00") {
        this.insuredFrom.certEndDate = this.fromData.applicant.certEndDate;
      } else {
        this.insuredFrom.certEndDate = "";
      }
      if (this.bbRadio == "1") {
        this.insuredFrom.certEndDate = "3000-01-01";
      }
    },
    //关闭日期弹窗
    cancelDate(type) {
      if (type == "tbStart") {
        //投保人起始时间
        this.isStartTB = false;
      } else if (type == "tbEnd") {
        //投保人截止时间
        this.isEndTB = false;
      } else if (type == "bbStart") {
        //被保人起始时间
        this.isStartBB = false;
      } else if (type == "bbEnd") {
        //被保人截止时间
        this.isEndBB = false;
      } else if (type == "applicantBirthaday") {
        //投保人生日
        this.isApplicantBirthaday = false;
      } else if (type == "insuredBirthaday") {
        //被保人生日
        this.isInsuredBirthaday = false;
      }
    },
    //回调日期
    callbackCertStartDate(type, value) {
      if (type == "tbStart") {
        //投保人起始时间
        this.isStartTB = false;
        this.fromData.applicant.certStartDate = value;
      } else if (type == "tbEnd") {
        //投保人截止时间
        this.isEndTB = false;
        this.fromData.applicant.certEndDate = value;
      } else if (type == "bbStart") {
        //被保人起始时间
        this.isStartBB = false;
        this.insuredFrom.certStartDate = value;
      } else if (type == "bbEnd") {
        //被保人截止时间
        this.isEndBB = false;
        this.insuredFrom.certEndDate = value;
      } else if (type == "applicantBirthaday") {
        //投保人生日
        this.isApplicantBirthaday = false;
        this.fromData.applicant.birthday = value;
      } else if (type == "insuredBirthaday") {
        //被保人生日
        this.isInsuredBirthaday = false;
        this.insuredFrom.birthday = value;
      }
    },
    //权益-查看详情
    rightsAndInterests(item) {
      const price = item.code == "ZZJ689" ? "150万" : "300万";
      this.$router.push({
        path: "/rightsAndInterests",
        query: {
          plansId: item.id,
          price: price,
        },
      });
    },
    //费率表、保险条款
    fileList(type) {
      this.$router.push({
        path: "/fileList",
        query: {
          type: type,
        },
      });
    },

    //客户告知书
    customerNotification() {
      this.$router.push({
        path: "/pdf",
        query: {
          type: "native",
        },
      });
    },

    //跳转投保须知
    insuranceNotice() {
      this.$router.push({
        path: "/insuranceNotice",
      });
    },
    //根据身份证号码获取出生日期
    getBirthdayFromIdCard(idCard) {
      var birthday = "";
      if (idCard != null && idCard != "") {
        if (idCard.length == 15) {
          birthday = "19" + idCard.substr(6, 6);
        } else if (idCard.length == 18) {
          birthday = idCard.substr(6, 8);
        }
        birthday = birthday.replace(/(.{4})(.{2})/, "$1-$2-");
      }
      return birthday;
    },
    //根据身份证号码获取出年龄
    getAge(identify) {
      var UUserCard = identify;
      if (UUserCard != null && UUserCard != "") {
        //获取年龄
        var myDate = new Date();
        var month = myDate.getMonth() + 1;
        var day = myDate.getDate();
        var age = myDate.getFullYear() - UUserCard.substring(6, 10) - 1;
        if (
          UUserCard.substring(10, 12) < month ||
          (UUserCard.substring(10, 12) == month &&
            UUserCard.substring(12, 14) <= day)
        ) {
          age++;
        }
        return age;
      }
    },
    // 根据出生日期获取年龄
    getAgeByBirthday(strAge) {
      var birArr = strAge.split("-");
      var birYear = birArr[0];
      var birMonth = birArr[1];
      var birDay = birArr[2];

      d = new Date();
      var nowYear = d.getFullYear();
      var nowMonth = d.getMonth() + 1; //记得加1
      var nowDay = d.getDate();
      var returnAge;

      if (birArr == null) {
        return false;
      }
      var d = new Date(birYear, birMonth - 1, birDay);
      if (
        d.getFullYear() == birYear &&
        d.getMonth() + 1 == birMonth &&
        d.getDate() == birDay
      ) {
        if (nowYear == birYear) {
          returnAge = 1; //
        } else {
          var ageDiff = nowYear - birYear; //
          if (ageDiff > 0) {
            if (nowMonth == birMonth) {
              var dayDiff = nowDay - birDay; //
              if (dayDiff < 0) {
                returnAge = ageDiff - 1;
              } else {
                returnAge = ageDiff;
              }
            } else {
              var monthDiff = nowMonth - birMonth; //
              if (monthDiff < 0) {
                returnAge = ageDiff - 1;
              } else {
                returnAge = ageDiff;
              }
            }
          } else {
            return "出生日期晚于今天，数据有误"; //返回-1 表示出生日期输入错误 晚于今天
          }
        }
        return returnAge;
      } else {
        return "输入的日期格式错误！";
      }
    },
    //根据身份证号码获取性别
    getSex(UUserCard) {
      return parseInt(UUserCard.substr(16, 1)) % 2 == 1 ? "1" : "2";
    },
    //切换针次
    changeResponsibility(index) {
      this.responsibilityActive = index;
      this.fromData.inputFactors.choiceType = index + 1;
      this.rbCalc();
    },
    //产品详情接口
    getProductDetail(productId) {
      productDetail({ productId }).then(({ success, result }) => {
        if (success) {
          const {
            plans,
            id,
            clauseCode,
            category,
            mainInsuranceCompanyId,
            fromName,
            mainName,
            subInsuranceCompanyId,
            subInsuranceCompanyName,
          } = result;
          const { code, securityPlanName } = plans[0];
          this.productBenefitDetail(plans[0].id);
          this.productDetail = result;
          this.fromData.riskCodeM = clauseCode;
          this.fromData.category = category;
          this.fromData.mainInsuranceCompanyId = mainInsuranceCompanyId;
          this.fromData.mainInsuranceCompanyName = fromName;
          this.fromData.productId = id;
          this.fromData.productName = mainName;
          this.fromData.subInsuranceCompanyId = subInsuranceCompanyId;
          this.fromData.subInsuranceCompanyName = subInsuranceCompanyName;
          this.fromData.planId = code;
          this.fromData.planName = securityPlanName;
        }
      });
    },
    //查找主附险数据
    selectRisk(id) {
      selectRisk(id).then(({ success, result }) => {
        if (success) {
          this.fromData.risks = result.map((e) => ({
            mainRiskFlag: e.riderType,
            mult: 1,
            riskCode: e.clauseCode,
            riskName: e.name,
          }));
        }
      });
    },

    // 监听投保人身份证件号码
    wathcApplicantCertNo() {
      if (
        this.fromData.applicant.certType === "0" &&
        this.fromData.applicant.certNo.length === 18
      ) {
        const idCard = this.fromData.applicant.certNo;
        this.fromData.applicant.birthday = this.getBirthdayFromIdCard(idCard);
        this.fromData.applicant.sex = this.getSex(idCard);
        this.sexApplicantValue = this.getSex(idCard) === "1" ? "男" : "女";
      }
    },

    // 监听被保人身份证件号码
    wathcInsuredCertNo() {
      if (
        this.insuredFrom.certType === "0" &&
        this.insuredFrom.certNo.length === 18
      ) {
        const idCard = this.insuredFrom.certNo;
        this.insuredFrom.birthday = this.getBirthdayFromIdCard(idCard);
        this.insuredFrom.sex = this.getSex(idCard);
        this.sexInsuredValue = this.getSex(idCard) === "1" ? "男" : "女";
      }
    },

    // 投保人选择身份证件类型
    changeCardTypeApplicant() {
      this.holderCardTypeApplicant = true;
    },
    // 投保人选择身份证件类型回调
    callbackCardTypeApplicant(value, id) {
      this.cardTypeApplicantValue = value;
      this.fromData.applicant.certType = id;
    },
    // 投保人选择性别
    changeSexApplicant() {
      this.holderSexApplicant = true;
    },
    // 投保人选择身份证件类型回调
    callbackSexApplicant(value, id) {
      this.sexApplicantValue = value;
      this.fromData.applicant.sex = id;
    },
    //投保人国籍选择
    changeNationalityApplicant() {
      this.holderNationalityApplicant = true;
    },
    // 投保人选择身份证件类型回调
    callbackNationalityApplicant(value, id) {
      this.nationalityApplicantValue = value;
      this.fromData.applicant.nationality = id;
    },

    // 选择被保人身份证件类型
    changeCardTypeInsured() {
      this.holderCardTypeInsured = true;
    },
    // 被保人选择身份证件类型回调
    callbackCardTypeInsured(value, id) {
      this.cardTypeInsuredValue = value;
      this.insuredFrom.certType = id;
    },
    // 被保人选择性别
    changeSexInsured() {
      this.holderSexInsured = true;
    },
    // 被保人选择身份证件类型回调
    callbackSexInsured(value, id) {
      this.sexInsuredValue = value;
      this.insuredFrom.sex = id;
    },
    //被保人国籍选择
    changeNationalityInsured() {
      this.holderNationalityInsured = true;
    },
    // 被保人选择身份证件类型回调
    callbackNationalityInsured(value, id) {
      this.nationalityInsuredValue = value;
      this.insuredFrom.nationality = id;
    },

    // 切换计划
    changeTit(index, item) {
      this.planActive = index;
      this.fromData.planId = item.code;
      this.fromData.planName = item.securityPlanName;
      this.productBenefitDetail(item.id);
    },
    //获取权益数据
    productBenefitDetail(plansId) {
      productBenefitDetail({
        plansId,
      }).then(({ success, result }) => {
        if (success) {
          this.fromData.inputFactors.DutyCode = result[0]?.code;
          this.rbCalc();
        }
      });
    },
    // 选择关系回调
    callbackRelationship(value, id) {
      const defaultInsuredFrom = {
        relation: "", //与投保人关系
        name: "", //姓名
        certNo: "", //证件号码
        phone: "", //联系电话
        certType: "",
        nationality: "",
        email: "",
        sex: "",
        birthday: "",
        age: "",
        certEndDate: "3000-01-01", //终止日期
        certStartDate: "",
        address: "",
      };

      if (id === "00") {
        this.insuredFrom = {
          ...defaultInsuredFrom,
          name: this.fromData.applicant.name,
          certNo: this.fromData.applicant.certNo,
          phone: this.fromData.applicant.phone,
          certType: this.fromData.applicant.certType,
          nationality: this.fromData.applicant.nationality,
          email: this.fromData.applicant.email,
          sex: this.fromData.applicant.sex,
          birthday: this.fromData.applicant.birthday,
          age: this.fromData.applicant.age,
          certStartDate: this.fromData.applicant.certStartDate,
          certEndDate: this.fromData.applicant.certEndDate,
          address: this.fromData.applicant.address,
        };
        this.bbRadio = this.tbRadio;
        this.cardTypeInsuredValue = this.cardTypeApplicantValue;
        this.sexInsuredValue = this.sexApplicantValue;
        this.nationalityInsuredValue = this.nationalityApplicantValue;
      } else {
        this.insuredFrom = { ...defaultInsuredFrom };
        this.bbRadio = "1";
        this.cardTypeInsuredValue = "";
        this.sexInsuredValue = "";
        this.nationalityInsuredValue = "";
      }
      this.relationshipValue = value;
      this.insuredFrom.relation = id;
      this.rbCalc();
    },
    //试算、投保json
    limit(type) {
      const getAge = (certType, certNo, birthday) => {
        return certType === "0"
          ? this.getAge(certNo)
          : this.getAgeByBirthday(birthday);
      };

      this.fromData.insureds = [];
      this.fromData.applicant.age = getAge(
        this.fromData.applicant.certType,
        this.fromData.applicant.certNo,
        this.fromData.applicant.birthday
      );
      this.insuredFrom.age = getAge(
        this.insuredFrom.certType,
        this.insuredFrom.certNo,
        this.insuredFrom.birthday
      );
      this.fromData.insureds.push(this.insuredFrom);

      const keys = [
        "vaccinationSiteId",
        "vaccinationSiteCityId",
        "vaccinationSiteAreaId",
        "vaccinationSiteCommunityId",
        "vaccinationSiteCommunityName",
      ];
      keys.forEach((key) => {
        this.fromData[key] = sessionStorage.getItem(key);
      });
      let params = {
        body: this.fromData,
      };
      if (type == "insure") {
        params.body.totalPrem = this.totalPrem;
        params.body.orderPrice = this.totalPrem;
        params.body.risks[0].riskAmnt = this.riskAmnt;
        params.body.risks[0].riskPrem = this.totalPrem;
      }
      return params;
    },
    //试算
    async rbCalc() {
      let params = this.limit();
      let isCalc = true;
      this.totalPrem = "";
      const applicantKeys = Object.keys(this.fromData.applicant);
      const insuredKeys = Object.keys(this.insuredFrom);
      isCalc =
        applicantKeys.every(
          (key) =>
            key === "email" ||
            key === "nationality" ||
            !!this.fromData.applicant[key]
        ) &&
        insuredKeys.every(
          (key) =>
            key === "phone" ||
            key === "email" ||
            key === "nationality" ||
            key === "age" ||
            !!this.insuredFrom[key]
        );
      if (isCalc) {
        const { success, result } = await rbCalc(params);
        if (success) {
          this.totalPrem = result.prem;
          this.riskAmnt = result.amnt;
        }
      }
    },
    //投保
    rbInsure() {
      if (!this.fromData.applicant.name) {
        this.$toast("请填写投保人姓名");
      } else if (!this.fromData.applicant.certType) {
        this.$toast("请选择投保人证件类型");
      } else if (
        this.fromData.applicant.certType == "1" &&
        this.fromData.applicant.nationality == ""
      ) {
        this.$toast("请选择投保人国籍");
      } else if (!this.fromData.applicant.certNo) {
        this.$toast("请填写投保人证件号码");
      } else if (
        !validIdCard(this.fromData.applicant.certNo) &&
        this.fromData.applicant.certType == 0
      ) {
        this.$toast("请填写正确的投保人身份证号码");
      } else if (!this.fromData.applicant.certStartDate) {
        this.$toast("请选择投保人证件有效起期");
      } else if (!this.fromData.applicant.birthday) {
        this.$toast("请选择投保人出生日期");
      } else if (!this.fromData.applicant.sex) {
        this.$toast("请选择投保人性别");
      } else if (!this.fromData.applicant.certEndDate) {
        this.$toast("请选择投保人证件有效止期");
      } else if (!this.fromData.applicant.phone) {
        this.$toast("请填写投保人手机号码");
      } else if (!validPhone(this.fromData.applicant.phone)) {
        this.$toast("请填写正确的投保人手机号码");
      } else if (!this.fromData.applicant.address) {
        this.$toast("请填写投保人联系地址");
      } else if (
        this.fromData.applicant.email &&
        !validEmail(this.fromData.applicant.email)
      ) {
        this.$toast("请填写正确的投保人邮箱");
      } else if (!this.insuredFrom.relation) {
        this.$toast("请选择与投保人关系");
      } else if (!this.insuredFrom.name) {
        this.$toast("请填写被保人姓名");
      } else if (!this.insuredFrom.certType) {
        this.$toast("请选择被保人证件类型");
      } else if (
        this.insuredFrom.certType == "1" &&
        this.insuredFrom.nationality == ""
      ) {
        this.$toast("请选择被保人国籍");
      } else if (!this.insuredFrom.certNo) {
        this.$toast("请填写被保人证件号码");
      } else if (
        !validIdCard(this.insuredFrom.certNo) &&
        this.insuredFrom.certType == 0
      ) {
        this.$toast("请填写正确被保人身份证号码");
      } else if (!this.insuredFrom.certStartDate) {
        this.$toast("请选择被保人证件有效起期");
      } else if (!this.insuredFrom.birthday) {
        this.$toast("请选择被保人出生日期");
      } else if (!this.insuredFrom.sex) {
        this.$toast("请选择被保人性别");
      } else if (!this.insuredFrom.certEndDate) {
        this.$toast("请选择被保人证件有效止期");
      } else if (!this.insuredFrom.phone) {
        this.$toast("请填写被保人手机号码");
      } else if (!this.insuredFrom.address) {
        this.$toast("请填写被保人联系地址");
      } else if (
        this.insuredFrom.email &&
        !validEmail(this.insuredFrom.email)
      ) {
        this.$toast("请填写正确的被保人邮箱");
      } else if (!this.checkFile) {
        this.$toast("请先阅读相关文件");
      } else {
        let params = this.limit("insure");
        if (params && this.totalPrem) {
          rbInsure(params).then(({ success, result }) => {
            if (success) {
              sessionStorage.setItem("totalPrem", this.totalPrem);
              sessionStorage.setItem(
                "productName",
                this.productDetail.mainName
              );
              this.$router.push({
                path: "/confirm",
                query: {
                  orderNo: result,
                },
              });
            }
          });
        }
      }
    },
  },
};
</script>
<style scoped lang="scss">
/deep/ .van-radio-group {
  margin-left: 15px;
}
/deep/ .van-radio__icon--checked .van-icon {
  background-color: $primary-color;
  border-color: $primary-color;
}
/deep/ .van-radio__label {
  font-size: 16px;
  font-weight: 400;
  color: #333333;
  line-height: 22px;
}
.insure-date {
  .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 14px;
    p {
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      line-height: 20px;
    }
  }
}
.responsibility {
  width: 100%;
  margin-top: 24px;
  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .responsibility-bg-active {
    background: url("../../assets/responsibility-active.png") no-repeat;
    background-size: 100%;
  }
  .responsibility-bg {
    background: url("../../assets/responsibility-noactive.png") no-repeat;
    background-size: 100%;
  }
  .item {
    width: 168px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    p {
      font-size: 14px;
      font-weight: bold;
      line-height: 20px;
    }
    .responsibilityColor-active {
      color: $primary-color;
    }
    .responsibilityColor {
      color: #aaaaaa;
    }
  }
  .msg {
    margin-top: 11px;
    width: 100%;
    height: 113px;
    padding: 25px 18px 0;
    background: url("../../assets/range-bg1.png") no-repeat;
    background-size: 100%;
    p {
      font-size: 16px;
      font-weight: bold;
      color: $primary-color;
      line-height: 22px;
    }
    div {
      display: flex;
      margin-top: 11px;
      img {
        width: 13px;
        height: 13px !important;
        margin-top: 3px;
      }
      p {
        flex: 1;
        width: 100%;
        font-size: 13px;
        font-weight: 400;
        color: #666666;
        line-height: 18px;
        margin-left: 8px;
      }
    }
  }
  .bgStyle {
    background: url("../../assets/range-bg2.png") no-repeat;
    background-size: 100%;
  }
}
input:disabled {
  background-color: #fff; //修改默认灰色样式
  color: #000;
  opacity: 1; //默认的不透明级别为0.3
  -webkit-text-fill-color: #000; //字体颜色安卓与IOS适配
  -webkit-opacity: 1; ///不透明级别安卓与IOS适配
}

.insure {
  padding: 10px;
  /deep/ .van-dialog__confirm {
    .van-button__text {
      color: $primary-color;
    }
  }
  /deep/ .van-cell {
    padding: 5px 0;
  }
  /deep/ .van-cell__title {
    width: 100px;
    font-size: 16px;
    font-weight: 500;
    color: #333333;
    line-height: 44px;
    margin-right: 0;
  }

  /deep/ .van-cell__value {
    width: 100%;
    flex: 1;
    margin-left: 16px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    .van-field__body {
      height: 100%;
    }
    input::-webkit-input-placeholder {
      color: #999;
    }
  }
  /deep/ .van-dialog {
    .van-cell {
      padding: 20px;
    }
  }
  .applicantContent {
    padding-bottom: 110px;
    .applicant-title {
      display: flex;
      align-items: center;
      margin-top: 20px;
      .titleNum {
        width: 18px;
        height: 18px;
        background: $primary-color;
        border-radius: 2px;
        font-size: 11px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        line-height: 16px;
        text-align: center;
      }
      .titleName {
        font-size: 18px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
        line-height: 25px;
        margin-left: 5px;
      }
    }
    .form {
      width: 100%;
      margin-top: 5px;
      .uni-form-item {
        padding: 5px 0;
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
        display: flex;
        align-items: center;
        .tit {
          width: 100px;
          font-size: 16px;
          font-weight: 500;
          color: #333333;
          line-height: 44px;
        }
        .uni-input {
          width: 100%;
          flex: 1;
          margin-left: 16px;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          line-height: 22px;
          padding: 0;
          .input {
            color: #000;
          }
          .inputno {
            color: #999;
          }
        }
        .arrow {
          width: 10px;
          font-size: 16px;
          margin-right: 17px;
        }
      }
    }
  }
}
.beneficiaryType {
  display: flex;
  margin-top: 34px;

  text {
    display: flex !important;
    align-items: center;
    justify-content: center;
    width: 160px;
    height: 72px;
    border-radius: 42px;
    font-size: 28px;
    font-weight: 400;
    line-height: 40px;

    margin-right: 40px;
  }

  .beneficiary-active {
    background: linear-gradient(90deg, #ffa466 0%, $primary-color 100%);
    color: #ffffff;
  }

  .beneficiary-no-active {
    border: 1px solid $primary-color;
    color: $primary-color;
    box-sizing: border-box;
  }
}

.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .beneficiaryTit {
    font-size: 16px;
    font-weight: bold;
    color: #333333;
    line-height: 48px;
    .line1 {
      float: left;
    }
    .line2 {
      float: left;
    }
  }
  .delete {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    color: #999999;
    line-height: 5px;
  }
}
.add {
  width: 124px;
  height: 36px;
  border-radius: 18px;
  border: 1px solid $primary-color;
  display: flex !important;
  align-items: center;
  justify-content: center;
  color: $primary-color;
  font-size: 14px;
  font-weight: 500;
  color: $primary-color;
  line-height: 36px;
  margin: 0 auto;
  margin-top: 20px;
}
.footer {
  width: 100%;
  height: 110px;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 100;
  background-color: #ffffff;
  border-top: 1px solid #e5e5e5;
  .handle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 12px;
    .price {
      display: flex;
      justify-content: flex-end;
      font-size: 16px;
      font-weight: 500;
      color: $primary-color;
      line-height: 16px;
      padding-left: 12px;
      .num {
        font-size: 20px;
      }
    }
    .btn {
      margin-right: 12px;
      width: 116px;
      height: 45px;
      line-height: 45px;
      background: linear-gradient(90deg, #ffa466 0%, $primary-color 100%);
      border-radius: 48px;
      color: #ffffff;
      font-size: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .file {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 12px;
    margin-top: 11px;
    img {
      width: 14px;
    }
    p {
      font-size: 13px;
      font-weight: 400;
      color: #333333;
      line-height: 13px;
    }
    .list {
      display: flex;
      align-items: center;
      p {
        padding: 0 10px;
        font-size: 13px;
        font-weight: 400;
        color: #569cff;
        line-height: 13px;
        border-right: 1px solid #e5e5e5;
      }
      p:last-child {
        border-right: none;
      }
    }
  }
}

.beneficiaryType {
  display: flex;
  margin-top: 34px;
  div {
    display: flex !important;
    align-items: center;
    justify-content: center;
    width: 90px;
    height: 40px;
    border-radius: 24px;
    font-size: 14px;
    font-weight: 400;
    line-height: 40px;
    margin-right: 10px;
  }

  .beneficiary-active {
    background: linear-gradient(90deg, #ffa466 0%, $primary-color 100%);
    color: #ffffff;
  }

  .beneficiary-no-active {
    border: 1px solid $primary-color;
    color: $primary-color;
    box-sizing: border-box;
  }
}

.btns {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15px;
  .item:first-child {
    margin-top: 0;
  }
  .item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    .name {
      height: 40px;
      border-radius: 24px;
      margin-right: 19px;
      font-size: 14px;
      font-weight: 400;
      line-height: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 10px;
    }
    .equity {
      // width: 82px;
      // height: 30px;

      padding: 7px 8px;
      background: #fff7f4;
      border-radius: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 14px;
      }
      p {
        font-size: 12px;
        font-weight: 400;
        color: $primary-color;
        line-height: 17px;
        margin-left: 4px;
      }
    }
  }

  // div {
  //   height: 40px;
  //   border-radius: 24px;
  //   margin-right: 19px;
  //   font-size: 14px;
  //   font-weight: 400;
  //   line-height: 14px;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   padding: 0 10px;
  // }

  .active {
    background: linear-gradient(90deg, #ffa466 0%, $primary-color 100%);
    color: #ffffff;
    // padding: 0 25px;
  }

  .no-active {
    // padding: 0 25px;
    border: 2px solid $primary-color;
    box-sizing: border-box;
    color: $primary-color;
  }
}
</style>
