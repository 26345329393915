export function validPhone(str) {
  let phoneRegex = /^1[3456789]\d{9}$/
  return phoneRegex.test(str.trim())
}

export function validIdCard(str) {
  let idcardRegex = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/
  return idcardRegex.test(str.trim())
}

export function validEmail(str) {
  // let emailRegex = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/
  let emailRegex = /^[\da-z]+([\-\.\_]?[\da-z]+)*@[\da-z]+([\-\.]?[\da-z]+)*(\.[a-z]{2,})+$/
  return emailRegex.test(str.trim())
}

